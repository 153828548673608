import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/weighitLogo.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoAngular from '../../assets/angular.svg';
import LogoIonic from '../../assets/ionic.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const Adapt = () => {
  const {mobileCar, ipadWorker, mobileInCar} = useStaticQuery(graphql`
    query {
      mobileCar: file(relativePath: { eq: "weighIt/mobileCar.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ipadWorker: file(relativePath: { eq: "weighIt/ipadWorker.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileInCar: file(relativePath: { eq: "weighIt/mobileInCar.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Helping companies comply with SOLAS regulations in the shipping industry</h2>
        <p>
          Loading a packed container onto a ship for export requires that the container has a verified weight.
          WeightIt built their business around solving this for companies and needed an easy to use app as part of their solution.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
          <h3>The Project</h3>
          <p>
            This project involved the design and development of a Web API as well as an iOS and Android mobile app for in-field data capture.
            The app provides operators the ability to quickly and accurately communicate VGM’s back to the WeightIt cloud server for later access.
          </p>
        </div>

        <img src={Logo} alt="WeighIt logo" className="logo" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked with WeighIt to build a simple and effective tool that included:</p>
          <ul>
            <li>Location and Timestamp Verification</li>
            <li>Container Number Validation</li>
            <li>Photo Capture</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={mobileCar.sharp.fluid} fadeIn alt="Man on iPhone near white car" />
          <Img fluid={ipadWorker.sharp.fluid} fadeIn alt="Worker looking at iPad" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <ul>
              <li>Mobile App Development</li>
              <li>API Development</li>
              <li>Systems Integration</li>
              <li>UX Design</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={mobileInCar.sharp.fluid} fadeIn alt="Man looking at iPhone in car" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoWebAPI} alt="Microsoft WebAPI logo" />
            <img src={LogoAngular} alt="Angular logo" />
            <img src={LogoIonic} alt="Ionic logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          Working with a developer that you can actually understand is like a breath of fresh air.
          Talen understood exactly what we needed and just made it happen.
        </ProjectQuote>
      </ProjectWrapper>

      <ProjectNav>
        <div className="prev">
          <img src={NavArrow} />
          <Link to="/work/informia">Informia</Link>
        </div>
      </ProjectNav>
    </Layout>
  );
};

export default Adapt;
